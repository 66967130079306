<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.99202 17.3333V12C7.99202 7.58133 11.5774 4 16 4C20.4227 4 24.008 7.58133 24.008 12V17.3333C24.008 18.648 24.6053 19.892 25.6307 20.716L26.0427 21.0467C27.2653 22.028 26.5707 24 25.0027 24H6.99735C5.42935 24 4.73468 22.028 5.95735 21.0467L6.36935 20.716C7.39602 19.892 7.99202 18.648 7.99202 17.3333Z"
      stroke="#23303D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14 27.9999H18"
      stroke="#23303D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
